<template>
  <div id="companyintro">
    <h2>公司简介</h2>
    <div class="a_line"></div>
    <div class="company_name">江苏数字矩阵科技有限公司</div>
    <div class="company_intro_content">
      <!-- <p>
        江苏数字矩阵科技有限公司由南京青云时代科技有限公司和南京鲲翼信息科技有限公司联合成立，历时13个月落地实施，平台导入“互联网+资讯+终端”新模式，大大降低了企业客户的服务成本，将资讯环节流程化、透明化、便捷化。
      </p> -->
      <p>江苏数字矩阵科技有限公司是一家新型的科技创新研发与运营公司，旗下专业一站式企业综合服务平台，引入“互联网+信息+终端”新模式，大大降低了企业客户的服务成本，将信息智能化、流程化、透明化</p>
    </div>
    
    <!-- <div class="video_intro">
      <div class="comp_video">
        <video width="900px" height="593px" controls="controls">
          <source src="~/assets/video/QQvideo.webm" type="video/webm" />
        </video>
      </div>
    </div> -->
    <!-- <div class="company_purpose_mission">
      <div class="company_purpose">
        <div>
          <p>使命</p>
          <p>为企业客户创造价值、为员工谋求福利。</p>
          <p>愿景</p>
          <p>一家受人尊重的企业服务商。</p>
          <p>文化</p>
          <p>专注、创新、和谐、忠诚</p>
        </div>
      </div>
      <div class="pur_mis_img pur_mis_img_2">
        <img src="~/assets/images/news/news01.png" alt="找不到资源" />
      </div>
    </div> -->
    <div class="company_purpose_mission">
      <div class="about">
          <!-- <img src="~/assets/images/news/aboutus.png" alt="找不到资源" /> -->
          <div><img src="~/assets/images/aboutus/comintro1.png" alt=""></div>
          <div><img src="~/assets/images/aboutus/comintro2.png" alt=""></div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "companyIntro",
  data() {
    return {};
  },
  created() {
    window.sessionStorage.setItem("aboutus_index", 0);
  },
  components: {},
};
</script>

<style lang="scss" scoped>
  @media(min-width: 1201px){
    @import '@/components/content/aboutus/companyIntro.scss';
  }
  @media(max-width: 1200px){
    @import '@/components/content/aboutus/companyIntro_phone.scss';
  }
</style>
